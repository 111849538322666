import React, {useState, useEffect} from 'react';
import Contenido from '../components/contenido/';
import {useTrackedState} from "../store";
import {useParams} from 'react-router-dom';
import useObtenerContinente from "./utils/useObtenerContinente";
import obtenerPosicionTextoAsociado from './utils/obtenerPosicionTextoAsociado';
import normalizeString from '../helpers/normalizeString';
import useObtenerIdiomasDisponibles from './utils/useObtenerIdiomasDisponibles';
import useObtenerItemTraduccion from './utils/useObtenerItemTraduccion';
const ContenidoContainer = () => {
  const state = useTrackedState();
  const {generalData} = state;
  const {dataTextos, continentes, traducciones, idiomasOriginales} = generalData;
  const [pending, setPending] = useState(true);
  const [errors, setErrors] = useState(null);
  const [abreviaturaContinente, posicionContinente] = useObtenerContinente();
  const [activeTextoAsociado, setActiveTextoAsociado] = useState(false)
  const [datosPrincipalesTexto, setDatosPrincipalesTextos] = useState({});
  const [datosTextoParaRenderizar, setDatosTextoParaRenderizar] = useState({});
  const idiomasDisponibles = useObtenerIdiomasDisponibles();
  const params = useParams();
  const {idTexto, idioma, continente} = params;
  const textos = dataTextos[abreviaturaContinente];
  
  const mostrarTexto = () => {
    let textoOriginal = obtenerTextoOriginal(datosPrincipalesTexto.textoAsociado)
    if(textoOriginal !== null){
      if(activeTextoAsociado){
        setDatosTextoParaRenderizar(datosPrincipalesTexto)
        setActiveTextoAsociado(false)
      }else{
        setDatosTextoParaRenderizar({...datosPrincipalesTexto,
          titulo: textoOriginal.titulo,
          autor: textoOriginal.autor,
          contenido: textoOriginal.contenido
        })
        setActiveTextoAsociado(true)
      }
    }
  }

  const obtenerPosicionIdiomaOriginal = (item) => {
    let posicionItem = null;
    idiomasOriginales.forEach((idioma, index)=> {
      if(idioma.codigo === item){
        posicionItem = index;
      }
    })
    return posicionItem;
  }

  const obtenerTextoOriginal = (textoAsociado) => {
    let textosAsociados = [];
    let textoOriginal = null;
    textos.forEach(item=>{
      if(item.textoAsociado === textoAsociado){
        textosAsociados.push(item)
      }
    })
    textosAsociados.forEach(item=>{
      if(!idiomasDisponibles.includes(item.idioma)){
        let posicionIdiomaOriginal =  obtenerPosicionIdiomaOriginal(item.idioma)
        textoOriginal = {
          titulo: item.titulo,
          autor: item.autor,
          contenido: item.contenido,
          idiomaContenido: posicionIdiomaOriginal !== null ? idiomasOriginales[posicionIdiomaOriginal][idioma] : ''
        }
      }
    })
    return textoOriginal; 
  }
  const posicionesTraducciones = {
    biografia : useObtenerItemTraduccion('biografia'),    
    idiomaOriginal : useObtenerItemTraduccion('idiomaOriginal'),
    continentes: useObtenerItemTraduccion('continentes')
  }

  const createTraducciones = () => {
    let traduccionesFinales = {};
    for(let i in posicionesTraducciones){
      traduccionesFinales[i] = traducciones[posicionesTraducciones[i]][idioma]
    }
    return traduccionesFinales ;
  }

  useEffect(()=>{
    setErrors(null)
    setActiveTextoAsociado(false)
    //obtener solo los textos del idioma
    let textosIdioma = textos.filter((entry)=>{
      return entry.idioma.indexOf(idioma) !== -1
    })
    const ObtenerPosicionTexto = () => {
      let posicion = null;
      textosIdioma.forEach((item, index)=>{
        if(item.id === idTexto){
          posicion = index;
        }
      })
      return posicion;
    }
    const obtenerTexto = () => {
      let posicionTexto = ObtenerPosicionTexto();
      let datosTexto = {
        titulo: '',
        autor: '',
        pais: '',
        id: '',
        contenido: '',
        linkSiguiente: '',
        linkAnterior: '',
        totalTextos: '',
        posicionTexto: '',
        biografia:'',
        mostrarTextoOriginal: null,
        textoOriginal : false,
        imagen: '',
        continente: '',
        textosInterfaz: {},
        idiomaOriginal: null,
        idioma: "",
        nombreContinente: ''
      }       
      if(posicionTexto !== null){
        const {titulo, autor, pais, textoAsociado, id, contenido, biografia, imagen, ilustrador} = textosIdioma[posicionTexto];
        const textoPrincipal = textos[obtenerPosicionTextoAsociado(textos, textoAsociado)];
        const linkSiguiente = () => {
          let link;
          if(posicionTexto < textosIdioma.length - 1){
            let textoSiguiente = textosIdioma[posicionTexto + 1]
            const textoSiguientePrincipal = textos[obtenerPosicionTextoAsociado(textos, textoSiguiente.id)];
            let tituloSiguiente = textoSiguiente.titulo === '' ? textoSiguientePrincipal.titulo : textoSiguiente.titulo
            link = `/${continente}/${idioma}/${textoSiguiente.id}/${normalizeString(tituloSiguiente)}`
          }else{
            if(continentes[posicionContinente].isNav === 'TRUE'){
              //AE: Aún se puede optimizar más y es posible que se deba modificar para que vaya al primer poema del siguiente continente.
              let posicionContinenteSiguiente = posicionContinente < continentes.length - 2 ? posicionContinente+1 : 0;//2 para no ir al prologo
              link = `/${normalizeString(continentes[posicionContinenteSiguiente][idioma])}/${idioma}`;
            }else{
              link = `/${normalizeString(traducciones[posicionesTraducciones.continentes][idioma])}/${idioma}`;
            }
          }
          return link;
        }

        const linkAnterior = () => {
          //AE: Esto se puede optimizar con la función de link siguiente
          let link;
          if(posicionTexto > 0){
            let textoAnterior = textosIdioma[posicionTexto - 1]
            const textoAnteriorPrincipal = textos[obtenerPosicionTextoAsociado(textos, textoAnterior.id)];
            let tituloAnterior = textoAnterior.titulo === '' ? textoAnteriorPrincipal.titulo : textoAnterior.titulo
            link = `/${continente}/${idioma}/${textoAnterior.id}/${normalizeString(tituloAnterior)}`
          }else{
            if(continentes[posicionContinente].isNav === 'TRUE'){
              let posicionContinenteAnterior = posicionContinente > 0 ? posicionContinente- 1 : continentes.length - 2;//2 para no ir al prologo
              link = `/${normalizeString(continentes[posicionContinenteAnterior][idioma])}/${idioma}`;
            }else{
              link = `#`
            }
          }
          return link;
        }
        let haveTextoOriginal = obtenerTextoOriginal(textosIdioma[posicionTexto].textoAsociado)
        datosTexto = {
          titulo: titulo === '' ? textoPrincipal.titulo : titulo,
          autor: autor === '' ? textoPrincipal.autor : autor,
          pais: pais === '' ? textoPrincipal.pais : pais,
          ilustrador: ilustrador === '' ? textoPrincipal.ilustrador : ilustrador,
          id: id,
          contenido: contenido,
          linkSiguiente: linkSiguiente(),
          linkAnterior: linkAnterior(),
          totalTextos: textosIdioma.length,
          posicionTexto: posicionTexto + 1,
          biografia:biografia,
          textoOriginal: haveTextoOriginal ? true : false, 
          imagen: imagen === '' ? textoPrincipal.imagen : imagen,
          continente: normalizeString(continentes[posicionContinente]['es']),
          textoAsociado: textoAsociado,
          textosInterfaz: createTraducciones(),
          idiomaOriginal: haveTextoOriginal ? haveTextoOriginal.idiomaContenido : null,
          idioma: idioma,
          nombreContinente: continentes[posicionContinente].isNav !== 'TRUE' ? continentes[posicionContinente][idioma] : null
        }

      }else{
        setErrors('El texto solicitado no existe o no se encuentra catalogado en este momento.')
      }
      setDatosPrincipalesTextos(datosTexto)
      setDatosTextoParaRenderizar(datosTexto)    
      setPending(false);  
    }
    obtenerTexto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTexto, idioma])
  return(
    <>
      {!pending &&
        <>
          <Contenido
            activeTextoAsociado={activeTextoAsociado}
            autor={datosTextoParaRenderizar.autor}
            biografia={datosTextoParaRenderizar.biografia}
            contenido={datosTextoParaRenderizar.contenido}
            continente={datosTextoParaRenderizar.continente}
            errors={errors}
            idioma= {idioma}
            idiomaOriginal={datosTextoParaRenderizar.idiomaOriginal}
            ilustrador={datosTextoParaRenderizar.ilustrador}
            imagen={datosTextoParaRenderizar.imagen}
            linkSiguiente={datosTextoParaRenderizar.linkSiguiente}
            linkAnterior={datosTextoParaRenderizar.linkAnterior}
            mostrarTextoOriginal={mostrarTexto}
            pais={datosTextoParaRenderizar.pais}
            posicionTexto={datosTextoParaRenderizar.posicionTexto}
            textosInterfaz={datosTextoParaRenderizar.textosInterfaz}
            textoOriginal={datosTextoParaRenderizar.textoOriginal}
            titulo={datosTextoParaRenderizar.titulo}
            totalTextos={datosTextoParaRenderizar.totalTextos}
            nombreContinente={datosTextoParaRenderizar.nombreContinente}
          />

        </>
      }
    </>
  )
}

export default ContenidoContainer;
