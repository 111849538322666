import React from "react";
import { Link } from "react-router-dom";

//Hooks
import useIsMobile from "../../hooks/useIsMobile";

//Componentes
import MapaMovil from "./MapaMovil";
import Mapa from "./Mapa";

//Estilos
import "../../scss/mapaContinentes/MapaContinentes.scss"

/**
  continentes=devuelve un arreglo de objetos con esta estructura:
  {
    nombre: '',
    link: '',
    numeroDePoemas:'',
    objectId: nombre normalizado siempre en español, util para la clase por ejemplo
  }
  palabraPoemas: esta es la palabra que va al lado del número de poemas, poems en inglés y poemas en español.    
**/

const MapaContinentes = (props) => {
  
  const { continentes } = props;
  const isMobile = useIsMobile();

  return (
    <div className="componente-menu-continentes d-flex px-0">
      <div className="w-100 px-lg-5">
        {
          !isMobile && (
          <>
            <Mapa {...props} />            
            <div className={`link-prologo px-lg-5 ${continentes[5].objectId}`}>
                <Link to={continentes[5].link}><h2 className="text-uppercase">{continentes[5].nombre}</h2></Link>
            </div>
          </>
          )
        }
        {
          isMobile && <MapaMovil {...props} />
        }
      </div>
    </div>
  )
}

export default MapaContinentes;