import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useOutsideElement from "../../helpers/useOutsideElement";

const MenuContinentes = (props) => {
    const { continentes, esDesplegable, linkNube, menuPrologos, palabraContinentes } = props;

    const [mostrarOcultarMenu, setMostrarOcultarMenu] = useState(!esDesplegable);

    const refMenuConinentes = useRef()

    useEffect(() => {
        setMostrarOcultarMenu(!esDesplegable)
    }, [esDesplegable])

    const mostrarMenu = (value) => {
        var newValue = typeof value === "object" ? false : value;
        if (esDesplegable) {
            setMostrarOcultarMenu(newValue);
        }
    }
    
    useOutsideElement(refMenuConinentes, mostrarMenu);

    return (
        <>
            <Link className="nube-menu" to={linkNube}>Nube</Link>
            <div ref={refMenuConinentes}>
                <div
                    className={`header-menu-continentes d-flex justify-content-center align-items-center ${mostrarOcultarMenu ? "encabezado-menu-activa" : ""}`}
                    onClick={() => {
                        if (esDesplegable && !menuPrologos) {
                            mostrarMenu(!mostrarOcultarMenu)
                        }
                    }}
                >
                    <h2 className="text-uppercase">{palabraContinentes}</h2>
                </div>
                {
                    mostrarOcultarMenu && (
                        <ul className="lista-contientes">
                            {
                                continentes.map(continente => {
                                    if(continente.objectId !== "prologos"){
                                        return (
                                            <li
                                                className={continente.objectId}
                                                key={`${continente.objectId}_header`}
                                                onClick={() => mostrarMenu(false)}
                                            >
                                                <Link to={continente.link} className={`item-continente-header text-uppercase ${continente.objectId} ${continente.isActive ? "continente-activo" : ""}`}>
                                                    {continente.nombre}
                                                </Link>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    )
                }
            </div>
        </>
    )
}

export default MenuContinentes;