const obtenerPosicionTextoAsociado = (data, id) => {
  let posicion = null;
  data.forEach((item, index)=>{
    if(item.id === id){
      posicion = index;
    }
  })
  return posicion;
}

export default obtenerPosicionTextoAsociado;