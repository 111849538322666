import normalizeString from "../../helpers/normalizeString";
import {useTrackedState} from "../../store";
import { useParams } from "react-router-dom";

const useObtenerContinentes = (includeAll) => {
  const state = useTrackedState();
  const {generalData} = state;
  const {continentes, dataTextos} = generalData;
  const params = useParams();
  const {idioma, continente} = params;
  let continentesParaRenderizar = [];
  continentes.forEach((item)=> {
    let dataContinente = dataTextos[item.continente];
    let textos = dataContinente.filter((entry)=>{
      return entry.idioma.indexOf(idioma) !== -1
    })
    if(item.isNav === "TRUE" || includeAll){
      continentesParaRenderizar.push({
        nombre: item[idioma],
        objectId: normalizeString(item.es),
        link: idioma ? `/${normalizeString(item[idioma])}/${idioma}` : '',
        numeroDePoemas: textos.length,
        isActive:  continente === undefined ? false : continente === normalizeString(item[idioma])
      })
    }
  })
  return continentesParaRenderizar;
}

export default useObtenerContinentes;