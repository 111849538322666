import React from 'react';

//Componentes
import ItemMenuContinente from './ItemMenuContinente';

//Hooks
import { useHeightContainer } from '../../hooks/useHeightContainer';

//Estilos
import "../../scss/MenuContiente.scss";

/**
    PROPS
    nombre: Nombre del continente
    objectId: Nombre del continente normalizado siempre en español, util para la clase por ejemplo
    poemas: Listado de todos los poemas con esta estructura {id, titulo, autor, pais, link}
    errors: null cuando no hay errores, texto cuando los hay,
    palabraPoemas: esta es la palabra que va al lado del número de poemas, poems en inglés y poemas en español. 
   
  **/
const MenuContinente = (props) => {
  const { nombre, objectId, palabraPoemas, poemas, errors } = props;

  const rutaPersona = require(`../../assets/img/menu_continente/${objectId}.gif`).default;
  const rutaImagenContinente = `url(${require(`../../assets/img/menu_continente/continente_${objectId}.png`).default})`;

  const [altoLista, refLista] = useHeightContainer(40);
  const [altoContenedorPadre, refContainer] = useHeightContainer();


  return (
    <div className="componente-menu-continente px-lg-5">
      {
        errors !== null && (
          <p>{errors}</p>
        )
      }

      <div
        className={`${objectId} contenedor-principal-componente menu-continente row justify-content-center align-items-center pt-lg-4 mx-0 mx-lg-auto px-0 pt-xxl-5`}
        style={{
          height: altoContenedorPadre
        }}
        ref={refContainer}
      >
        <div className={`columna-imagenes columna-imagen-${objectId} col-12 col-lg-auto h-100 px-0 mb-4 mb-lg-0 mt-3 mt-lg-0 d-flex align-self-endjustify-content-end align-items-end`}>
          {/* <video className="col-12 img-persona-volando mx-auto" key={`${objectId}_video`} controls={false} loop playsInline autoPlay muted preload >
            <source src={rutaPersona} type="video/mp4" />
          </video> */}
          <img className="col-12 img-persona-volando mx-auto" src={rutaPersona} alt={`persona${objectId}`} />
          <div
            className="w-100 h-100 imagen-contiente"
            style={{
              backgroundImage: rutaImagenContinente,
            }}
          />
        </div>

        <div className="h-100 px-0 pl-lg-3 col-12 col-lg-auto columna-textos">
          <div className="mb-3 pl-3 pl-lg-0">
            <h2 className="text-uppercase">{nombre} <span className="num-poemas">{`{${poemas.length} ${palabraPoemas}}`}</span> </h2>
          </div>
          <ul
            className="lista-poemas scrollbar px-3 pr-lg-5 mb-0"
            ref={refLista}
            style={{
              height: altoLista
            }}
          >
            {
              poemas.map((poema, i) => (
                <ItemMenuContinente
                  key={`poema${i}_${objectId}`}
                  poema={poema}
                />
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MenuContinente;
