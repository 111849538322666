import { useState, useEffect, useCallback } from 'react';
import useIsMobile from './useIsMobile';

function useHeightContainer(value) {
    const [altoContenedorPadre, setAltoContenedorPadre] = useState(0);
    const [height, setHeight] = useState(window.innerHeight);
    const isMobile = useIsMobile()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refContainer = useCallback((node)=>{
      if(node !== null){
        let valueToSubstract = value ? value : 0;
        let heightContainer = (height - node.getBoundingClientRect().top) - valueToSubstract ;
        setAltoContenedorPadre(isMobile ? 'auto' : heightContainer );
      }
    })

    useEffect(() => {
        const changeHeight = () => {
          setHeight(window.innerHeight)
        };
        
        changeHeight();
        window.addEventListener('resize', changeHeight)
        return () => {
          window.removeEventListener('resize', changeHeight)
        }
    }, [])
    return [ altoContenedorPadre, refContainer]
}

export { useHeightContainer };