import {useTrackedState} from "../../store";

const useObtenerItemTraduccion = (item) => {
  const state = useTrackedState();
  const {generalData} = state;
  const {traducciones} = generalData;

  let posicionItem = null;
  traducciones.forEach((traduccion, index)=> {
    if(traduccion.item === item){
      posicionItem = index;
    }
  })
  return posicionItem;
}

export default useObtenerItemTraduccion;