import React from "react";

const SVG_LUPA = (props) => {

    const {color}= props;

    return (
        <svg className="col-12 px-0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 33.1 52" style={{ overflow: "visible", enablebackground: "new 0 0 33.1 52" }} xmlSpace="preserve">
            <defs>
            </defs>
            <g>
                <defs>
                    <rect id="SVGID_1_" width="33.1" height="52" />
                </defs>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
                </clipPath>
                <ellipse 
                    cx="16.5" cy="16.7" rx="13.4" ry="13.5"
                    style={{
                        clipPath: "url(#SVGID_2_)",
                        fill: "none",
                        stroke: color,
                        strokeWidth: 3.2872,
                        strokeMiterlimit: 10
                    }}
                />
                <path 
                    d="M18.8,32.8c0,0-2,0.5-4.2-0.1v15.4v2.1c0,1.8,4.2,1.8,4.2,0C18.8,48,18.8,32.8,18.8,32.8" 
                    style={{
                        clipPath: "url(#SVGID_2_)",
                        fill: color
                    }}
                />
            </g>
        </svg>
    )
}

export default SVG_LUPA;