import React, { useEffect, useState } from "react";
import {useTrackedState} from "../store";
import MenuContinente from '../components/MenuContinente';
import { useParams } from "react-router-dom";
import normalizeString from '../helpers/normalizeString';
import useObtenerContinente from "./utils/useObtenerContinente";
import obtenerPosicionTextoAsociado from './utils/obtenerPosicionTextoAsociado';
import useObtenerItemTraduccion from "./utils/useObtenerItemTraduccion";
const Continente= () => {
  const state = useTrackedState();
  const [dataContinente, setDataContinente] = useState({});
  const [pending, setPending] = useState(true);
  const params = useParams();
  const {continente, idioma} = params;
  const [abreviaturaContinente, posicionContinente] = useObtenerContinente();
  const [errors, setErrors] = useState(null);
  const {generalData} = state;
  const {continentes, dataTextos, traducciones} = generalData;
  const posicionItem = useObtenerItemTraduccion('poemas');
  useEffect(()=>{
    const obtenerContinente = () => {
      let datosContinente = {
        nombre: '',
        id: '',
        objectId: '',
        poemas: []
      }      
      if(posicionContinente !== null){
        datosContinente = {
          nombre: continentes[posicionContinente][idioma],
          id: abreviaturaContinente,
          objectId: normalizeString(continentes[posicionContinente][idioma]),
          poemas: ObtenerTextosContinente(),
          palabraPoemas: traducciones[posicionItem][idioma]
        }
      }else{
        setErrors('El continente solicitado no existe o no se encuentra catalogado en este momento.')
      }
      setDataContinente(datosContinente)    
      setPending(false);
    }

    const ObtenerTextosContinente = () => {
      let dataContinente = dataTextos[abreviaturaContinente];
      let textos = dataContinente.filter((entry)=>{
        return entry.idioma.indexOf(idioma) !== -1
      })
      let textosParaMenu = [];
      textos.forEach(item =>{
        const {autor, titulo, pais, id, textoAsociado} = item;
        const textoPrincipal = dataContinente[obtenerPosicionTextoAsociado(dataContinente, textoAsociado)];
        let tituloParaMostrar = titulo === '' ? textoPrincipal.titulo : titulo;  
        textosParaMenu.push({
          id: id,
          autor: autor === '' ? textoPrincipal.autor : autor,
          titulo: tituloParaMostrar,
          pais: pais === '' ? textoPrincipal.pais : pais,
          link: `/${continente}/${idioma}/${id}/${normalizeString(tituloParaMostrar)}`
        })
      })
      return textosParaMenu;
    }
    obtenerContinente()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continente, idioma])

  return(
    <>
      {!pending &&
        <>
          <MenuContinente
            nombre={dataContinente.nombre}
            objectId={posicionContinente !== null ?normalizeString(continentes[posicionContinente]['es']) : ''}
            poemas={dataContinente.poemas}
            errors={errors}
            palabraPoemas={dataContinente.palabraPoemas}
          />
        </>
      }
    </>
  )
}

export default Continente;