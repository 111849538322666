import React from 'react';
import { Link } from 'react-router-dom';

import MenuContinentes from './MenuContinentes';

import "../../scss/Header.scss"
const MenuEscritorio = (props) => {
  const { continentes, idiomas, showContinentes } = props;

  return (
    <div className="d-flex align-items-center py-3 px-lg-5"
      style={{ justifyContent: showContinentes ? 'space-between' : 'flex-end' }}>
      {
        continentes !== null && (
          <div className="opciones-contientes"
            style={{ display: showContinentes ? 'block' : 'none' }}
          >
            <MenuContinentes {...props} />
          </div>
        )
      }

      <div className="opciones-idioma d-flex justify-content-start align-items-center">
        {
          idiomas.map(idioma => {
            return (
              <Link className={`text-uppercase ${idioma.isActive ? "idioma-activo" : ""}`} key={`${idioma.name}_header`} to={idioma.link}>
                <p>{idioma.name}</p>
              </Link>
            )
          })
        }
        <Link to="/" className="btn-home"></Link>
      </div>
    </div>
  )
}

export default MenuEscritorio;