import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import Header from '../components/header/';
import normalizeString from "../helpers/normalizeString";
import { useTrackedState } from "../store";
import useObtenerContinente from "./utils/useObtenerContinente";
import useObtenerContinentes from './utils/useObtenerContinentes';
import useObtenerItemTraduccion from "./utils/useObtenerItemTraduccion";

const HeaderContainer = () => {
  const state = useTrackedState();
  const location = useLocation();
  const { idioma, continente, idTexto } = useParams();
  const [pending, setPending] = useState(true);
  const [dataHeader, setDataHeader] = useState({});
  const { generalData } = state;
  const { traducciones, idiomas, continentes, dataTextos } = generalData;
  const infoContinentes = useObtenerContinentes(true);
  const posicionPalabraContinentes = useObtenerItemTraduccion('continentes');
  const posicionPalabraCreditos = useObtenerItemTraduccion('creditos');
  const [abreviaturaContinente, posicionContinente] = useObtenerContinente();
  const textos = dataTextos[abreviaturaContinente];
  const routes = ['credits', 'creditos'];

  const includeRoutes = () => {
    let validUrl = true;
    routes.forEach(item => {
      if (location.pathname.includes(item)) {
        validUrl = false;
      }
    })
    return validUrl;
  }

  useEffect(() => {
    const obtenerContinentes = () => {
      let palabraContinentes = traducciones[posicionPalabraContinentes][idioma];
      let poemasDesplegableConditional = continente !== 'prologos' && continente !== 'prologues' && idTexto !== undefined;

      let datosContinentes = {
        continentes: infoContinentes,
        palabraContinentes: palabraContinentes,
        idiomas: createIdiomas(),
        showContinentes: includeRoutes(),
        esDesplegable: !poemasDesplegableConditional,
        menuPrologos: (continente === 'prologos' || continente === 'prologues') && idTexto !== undefined,
        linkNube: `/${palabraContinentes.toLowerCase()}/${idioma}`,
      }
      
      datosContinentes.continentes.forEach(element => {
        if(element.objectId === "prologos"){
          element.link = urlPrologo();
        }
      });

      setDataHeader(datosContinentes)
      setPending(false);

    }
    if (idioma !== undefined) {
      obtenerContinentes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma, continente, idTexto])


  const urlPrologo = () => {
    let seccion, id, nombreTexto, url;

    continentes.forEach(element => {
      if (element.continente === "prol") {
        seccion = normalizeString(element[idioma]);
      }
    });

    let textosIdioma = dataTextos.prol.filter((entry) => {
      return entry.idioma.indexOf(idioma) !== -1
    })

    id = textosIdioma[0].id;
    nombreTexto = normalizeString(textosIdioma[0].titulo);

    url = `/${seccion}/${idioma}/${id}/${nombreTexto}`
    return url;
  }

  const createIdiomas = () => {
    let IdiomasToRender = [];
    idiomas.forEach((item) => {
      IdiomasToRender.push({
        name: item[idioma],
        link: obtenerLinks(item.objectId),
        isActive: idioma === item.objectId
      })
    })
    return IdiomasToRender
  }

  const obtenerLinks = (idioma) => {
    let continentesConditional = location.pathname.includes('/continents') || location.pathname.includes('/continentes');
    let creditosConditional = location.pathname.includes('/credits') || location.pathname.includes('/creditos');
    let menuContinenteConditional = continente !== undefined && idTexto === undefined;
    let contenidoConditional = idTexto !== undefined;
    let route = '';
    if (continentesConditional) {
      route = `/${normalizeString(traducciones[posicionPalabraContinentes][idioma])}/${idioma}`
    } else if (creditosConditional) {
      route = `/${normalizeString(traducciones[posicionPalabraCreditos][idioma])}/${idioma}`
    } else if (menuContinenteConditional) {
      route = posicionContinente !== null ? `/${normalizeString(continentes[posicionContinente][idioma])}/${idioma}` : ''
    } else if (contenidoConditional) {
      let posicionTexto = ObtenerPosicionTexto();
      if (posicionTexto !== null) {
        let textoAsociado = textos[posicionTexto].textoAsociado;
        let posicionLinkTexto;
        textos.forEach((item, index) => {
          if (item.idioma === idioma && item.textoAsociado === textoAsociado) {
            posicionLinkTexto = index;
          }
        })
        let textoFinal = textos[posicionLinkTexto];
        route = `/${normalizeString(continentes[posicionContinente][idioma])}/${idioma}/${textoFinal.id}/${normalizeString(textoFinal.titulo)}`;
      } else {
        route = ''
      }
    }
    return route
  }

  const ObtenerPosicionTexto = () => {
    let posicion = null;
    textos.forEach((item, index) => {
      if (item.id === idTexto) {
        posicion = index;
      }
    })
    return posicion;
  }

  const routesConditional = (idTexto !== undefined) || (continente !== undefined);
  return (
    <>
      {!pending && routesConditional &&
        <Header
          continentes={dataHeader.continentes}
          esDesplegable={dataHeader.esDesplegable}
          idiomas={dataHeader.idiomas}
          linkNube={dataHeader.linkNube}
          palabraContinentes={dataHeader.palabraContinentes}
          showContinentes={dataHeader.showContinentes}
          menuPrologos={dataHeader.menuPrologos}
        />
      }
    </>
  )
}

export default HeaderContainer;