import React from "react";
export function cambiarSaltoLinea(caracter, texto) {
    let cadenaTexto = String(texto).split(caracter);

    let nuevoTexto = [];
    for (var i = 0; i <= cadenaTexto.length; i++) {
        var num = i;
        console.log(cadenaTexto[num])
        if(cadenaTexto[num] !== undefined){
            nuevoTexto.push(
                <img key={`img${num}`} className="w-100" src={require(`../assets/img/${cadenaTexto[num]}`).default} alt="Imagen poema" />
            )
        }
        // require("../assets")
    }
    return nuevoTexto;
}