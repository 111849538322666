import React from "react";
import { Link } from "react-router-dom";

const ItemMenu = (props) => {
    const { poema } = props;
    
    const createMarkup = (contenido) => {
        return { __html: contenido };
    }

    return (
        <li>
            <Link className="item-poema row flex-lg-nowrap" to={poema.link}>
                <p className="col-md-5 px-0 titulo-poema" dangerouslySetInnerHTML={createMarkup(poema.titulo)}/>
                <p className="col-md-4 px-0 pl-lg-3">{poema.autor}</p>
                <p className="pl-lg-3 pr-lg-1">{poema.pais}</p>
            </Link>
        </li>
    )
}

export default ItemMenu;