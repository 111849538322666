import React, { useEffect, useState, useRef } from 'react';
import "../../scss/Header.scss"
import useIsMobile from './../../hooks/useIsMobile';
import MenuEscritorio from './MenuEscritorio';
import MenuMovil from './MenuMovil';
const Header = (props) => {
  /**
    Estas propiedas se usan tanto para los textos del prólogo como para los poemas
    PROPS
    continentes=devuelve un arreglo de objetos con esta estructura:
      {
        nombre: '',
        link: '',
        numeroDePoemas:'',
        objectId: nombre normalizado siempre en español, util para la clase por ejemplo,
        isActive: true cuando es el continente activo
      }
    palabraContinentes=para el texto del menú
    idiomas=devuelve un arreglo de objetos con esta estructura
      {
        name: '',
        link: '',
        isActive
      }
   
  **/
  const [headerDimentions, setHeaderDimentions] = useState({ height: 0})
  const { height} = headerDimentions;
  const header = useRef(null);

  const isMobile = useIsMobile();

  useEffect(()=>{
    const createHeight = () => {
      const node = header.current;
      if (node !== null) {
        let height = node.getBoundingClientRect().height
        setHeaderDimentions({
          height: height
        })
      }
    }
    createHeight();
    window.addEventListener('resize', createHeight)
    return () => {
      window.removeEventListener('resize', createHeight)
    }
  }, [])


  return (
    <>
    <header className="menu-principal w-100" ref={header}>
      {isMobile && <MenuMovil {...props} />}
      {!isMobile && <MenuEscritorio {...props} />}
    </header>

    <div className="header-space"
      style={{
        height: `${height}px`
      }}
    ></div>
    </>
  )
}

export default Header;