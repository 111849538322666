import React, { useEffect } from 'react';

import Biografia from "./Biografia"
import Contenido from "./Contenido";
import CambiarContenido from "./CambiarContenido";

import "../../scss/contenido/Contenido.scss"
import { useHeightContainer } from "../../hooks/useHeightContainer";
import { useHistory } from 'react-router';

const ContenidoSeccion = (props) => {
  /**
    Estas propiedas se usan tanto para los textos del prólogo como para los poemas
    PROPS
    activeTextoAsociado=true o false, este se usa para controlar el estado del toggle
    autor
    biografia=HTML, usar las funciones mencionadas arriba, cuando no existe devuelve ''
    contenido: Esto devuelve el HTML por lo que hay que usar esta funcion
      const createMarkup = (body) => {
        return {__html: body};
      }
      y esto como propiedad del div donde se inserta el texto -> dangerouslySetInnerHTML={createMarkup(contenido)
    continente=Nombre del continente normalizado siempre en español, util para la clase por ejemplo que determina los colores    
    errors=null cuando no hay errores, texto cuando los hay
    ilustrador
    imagen=ruta de la imagen que debe estar en la carpeta assets, probablemente se necesite usar el require
    linkAnterior=link para la flecha anterior
    linkSiguiente=link para la flecha siguiente    
    mostrarTextoOriginal=funcion para mostrar/ocultar el texto original
    pais
    posicionTexto=Este es el número que va antes de /
    textoOriginal=booleana, para mostrar el toggle de ver texto original
    titulo
    totalTextos=Este es el número que va despues de /
    textosInterfaz=Devuelve un objeto con los textos que se deben usar para biografía y para leer en idioma original
    idiomaOriginal= devuelve el idioma original en Inglés o Español y Devuelve null cuando no existe el nombre del idioma original
    nombreContinente= devuelve el nombre del continente, va en null cuando no hay que mostrarlo
   
  **/
  const { biografia, continente, errors, linkAnterior, linkSiguiente, nombreContinente } = props;
  const [altoContenedorPadre, contenedorPadre] = useHeightContainer(50);
  const history = useHistory();

  useEffect(() => {
    const checkKey = (e) => {
      switch (e.keyCode) {
        case 37:
          history.push(linkAnterior);
          break;
        case 39:
          history.push(linkSiguiente);
          break;
        default:
          return false
      }
    }
    document.addEventListener("keydown", checkKey)
    return () => {
      document.removeEventListener("keydown", checkKey)
    }
  }, [linkAnterior, linkSiguiente, history])

  return (
    <div className={`px-lg-5 ${continente}`}>
      {
        errors !== null && (
          <p>{errors}</p>
        )
      }

      {
        nombreContinente !== null && (
          <h2 className="titulo-seccion text-uppercase px-3 px-lg-0 mb-0">{nombreContinente}</h2>
        )
      }
      <div className={`pt-2 componente-contenido row mx-0 flex-column-reverse flex-lg-row contenido-poema`}
        style={{
          height: altoContenedorPadre
        }}
        ref={contenedorPadre}
      >
        {
          continente !== 'prologos' && (
            <div className={`contenido-biografia col-lg-3 pl-lg-0 h-100 d-flex flex-column ${biografia ? "justify-content-end align-self-end" : ""} `}>
              {
                biografia !== "" && (
                  <Biografia {...props} />
                )
              }
            </div>
          )
        }


        <div className="contenedor-contenido col-lg-9 px-lg-0 h-100">
          <Contenido {...props} />
        </div>
      </div>

      <div className="contenedor-cambiar-contenido col-lg-9 px-0 ml-auto">
        <CambiarContenido {...props} />
      </div>
    </div>
  )

}

export default ContenidoSeccion;
