import { Route, Switch, Redirect } from 'react-router-dom';

import { useTrackedState } from "./store";

//Componentes
import Init from './containers/Init';
import Continente from './containers/ContinenteContainer';
//import InicioContainer from './containers/InicioContainer';
import ContenidoContainer from './containers/ContenidoContainer';
import Continentes from './containers/Continentes';
import HeaderContainer from './containers/HeaderContainer';
import PortadaContainer from './containers/PortadaContainer';
import Creditos from './containers/CreditosContainer';
import ScrollToTop from './helpers/ScrollToTop';

const App = () => {
  const state = useTrackedState();
  const { pending } = state;
  return (
    <>
      {pending &&
        <Init />
      }
      {!pending &&
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={MainRoutes} />
            <Route exact path="/:idioma" component={MainRoutes} />
            <Route exact path="/:continente/:idioma" component={MainRoutes} />
            <Route exact path="/:continente/:idioma/:idTexto" component={MainRoutes} />
            <Route exact path="/:continente/:idioma/:idTexto/:texto" component={MainRoutes} />
          </Switch>
        </ScrollToTop>
      }
    </>
  );
}

const MainRoutes = () => {
  let navegadorIdioma = navigator.language.split('-')[0];
  return (
    <>
      <HeaderContainer />
      <Switch>
        <Route exact path="/">
          <Redirect to={`/${navegadorIdioma}`} />
        </Route>
        {/*AE: Esto se hace para usar una lógica diferente al del container de Continente individual. Si tuvieramos mas secciones parecidas al mapa lo modificaría, pero como es una y es poco probable que cambie lo pongo hard-coded*/}
        {/* Portada */}
        <Route exact path="/:idioma" component={PortadaContainer} />

        {/* Créditos */}
        <Route exact path="/creditos/:idioma" component={Creditos} />
        <Route exact path="/credits/:idioma" component={Creditos} />

        {/* Mapa continentes */}
        <Route exact path="/continentes/:idioma" component={Continentes} />
        <Route exact path="/continents/:idioma" component={Continentes} />

        {/* Menú continente */}
        <Route exact path="/:continente/:idioma" component={Continente} />

        {/* Poema o prologo */}
        <Route exact path="/:continente/:idioma/:idTexto" component={ContenidoContainer} />
        <Route exact path="/:continente/:idioma/:idTexto/:texto" component={ContenidoContainer} />
      </Switch>
    </>

  )
}

export default App;
