import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useOutsideElement from "../../helpers/useOutsideElement";

const MenuMovil = (props) => {
  const { continentes, idiomas } = props;

  const [mostrarOcultarMenu, setMostrarOcultarMenu] = useState(false);

  const refMenuConinentes = useRef()

  const mostrarMenu = (value) => {
    var newValue = typeof value === "object" ? false : value;
    setMostrarOcultarMenu(newValue);
  }

  useOutsideElement(refMenuConinentes, mostrarMenu);

  return (
    <div >
      <div className="py-2">
        <div className="nube-menu " onClick={() => { mostrarMenu(!mostrarOcultarMenu); }}>Menú</div>

        <div className={`menu-movil ${mostrarOcultarMenu ? "mostart-menu" : ""}`} ref={refMenuConinentes}>
          <div className="px-4 mt-3 d-flex justify-content-between align-items-center ">
            <Link to="/" className="btn-home text-uppercase">Inicio</Link>
            <div className="cerrar-menu" onClick={() => { mostrarMenu(!mostrarOcultarMenu); }}>Cerrar</div>
          </div>

          <hr />

          <ul className="mt-3 mb-0 lista-idiomas">
            {
              idiomas.map(idioma => {
                return (
                  <li className="px-4 py-2" key={`${idioma.name}_header`}>
                    <Link className={`text-uppercase ${idioma.isActive ? "idioma-activo" : ""}`} to={idioma.link}>
                      <p>{idioma.name}</p>
                    </Link>
                  </li>
                )
              })
            }
          </ul>
          <hr />

          <div className={`px-4 py-1 prologos`} onClick={() => mostrarMenu(false)} >
            <Link to={continentes[5].link} className={`w-100 item-continente-header text-uppercase prologos ${continentes[5].isActive ? "continente-activo" : ""}`}>
              {continentes[5].nombre}
            </Link>
          </div>
          <hr />
          <div>
            <ul className="mb-3 lista-contientes">
              {
                continentes.map(continente => {
                  return (
                    <>
                      {(() => {
                        if (continente.objectId !== "prologos") {
                          return (
                            <li
                              className={`px-4 py-1 order-3 ${continente.objectId}`}
                              key={`${continente.objectId}_header`}
                              onClick={() => mostrarMenu(false)}
                            >
                              <Link to={continente.link} className={`w-100 item-continente-header text-uppercase ${continente.objectId} ${continente.isActive ? "continente-activo" : ""}`}>
                                {continente.nombre}
                              </Link>
                            </li>
                          )
                        }
                      })()}
                    </>
                  )
                })
              }
            </ul>
          </div>
          <hr />
        </div>
      </div>
    </div>
  )
}

export default MenuMovil;