import React from 'react';
import "../scss/preloader.scss";

const Preloader = (props) => {
  const {percentage} = props;
  return( 
    <div className="preload">
      <div className="container">
        <p>Cargando... <span>{percentage}%</span></p>
      </div>
    </div>
  )
}

export default Preloader;