import React from "react";
import { Link } from "react-router-dom";

const MapaMovil = (props) => {
    const { continentes, palabraPoemas } = props;

    return (
        <div className="menu-movil-continentes w-100">
            {
                continentes.map(continente => {
                    return (
                        <Link to={continente.link} className={`w-100 ${continente.objectId}`} key={`${continente.objectId}_movil`}>
                            <div className="item-continente-movil w-100  d-flex flex-column justify-content-center align-items-center ">
                                <p>{continente.nombre}</p>
                                {continente.objectId !== "prologos" && (
                                    <span className="num-poemas" >{`{${continente.numeroDePoemas} ${palabraPoemas}}`}</span>
                                ) }                                
                            </div>
                        </Link>
                    )
                })
            }
        </div>
    )
}

export default MapaMovil;