import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import MapaContinentes from "../components/Mapa/";
import {useTrackedState} from "../store";
import useObtenerContinentes from "./utils/useObtenerContinentes";
import useObtenerItemTraduccion from "./utils/useObtenerItemTraduccion";
import normalizeString from './../helpers/normalizeString';

const Continentes = () => {
  const state = useTrackedState();
  const {idioma} = useParams();
  const [pending, setPending] = useState(true);
  const [dataContinentes, setDataContinentes] = useState({});
  const {generalData} = state;
  const {continentes, dataTextos, traducciones } = generalData;
  const infoContinentes = useObtenerContinentes(true);
  const posicionItem = useObtenerItemTraduccion('poemas');
  useEffect(()=>{
    const obtenerContinentes = () => {
      let datosContinentes = {
        continentes: infoContinentes,
        palabraPoemas: traducciones[posicionItem][idioma]
      }

      datosContinentes.continentes.forEach(element => {
        if(element.objectId === "prologos"){
          element.link = urlBotonContinuar();
        }
      });

      setDataContinentes(datosContinentes)    
      setPending(false);
    }
    obtenerContinentes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma])

  const urlBotonContinuar =()=>{
    let seccion, id, nombreTexto, url;

    continentes.forEach(element => {
      if(element.continente === "prol"){
        seccion = normalizeString(element[idioma]);
      }
    });
    
    let textosIdioma = dataTextos.prol.filter((entry)=>{
      return entry.idioma.indexOf(idioma) !== -1
    })

    id = textosIdioma[0].id;
    nombreTexto = normalizeString(textosIdioma[0].titulo);
    
    url = `/${seccion}/${idioma}/${id}/${nombreTexto}`
    return url;
  }

  return(
    <>
      {!pending &&
        <>
          <MapaContinentes
            continentes={dataContinentes.continentes}
            palabraPoemas={dataContinentes.palabraPoemas}
          />
        </>    
      }
    </>
  )
}

export default Continentes;