/*Esto se puede convertir en una carpeta de ser necesario
Funciones:
  -Actualizar los datos iniciales a partir de la información del excel
  -Los datos deben ser condicionales, es decir en desarrollo llamamos google sheets y en producción un archivo json local
  -Actualizar el título de la página si estamos en otro idioma
  -Actualizar el idioma actual si el idioma del navegador es diferente o si navegamos por url
  -Realizar la precarga de las imágenes 
Estructura:
  -Recibe el estado y el dispatch del store
  -Este componente va a ir antes del Switch en App.js ya que es general a toda la aplicación. No estoy seguro si el estado del loading se hace directamente acá o en el Store, probemos acá y si algo lo cambiamos. Mientras está en loading debe mostrar la pantalla negra y el loading de las imágenes.
  -Al finalizar la precarga debemos redirigir con history a /pathname/:lenguaje
*/


import React, { useEffect, useState } from "react";
import { useDispatch } from "../store";
import Preloader from '../components/Preloader';
import imagesPerLoad from "../modules/imagesPreLoad";
let DataTextos = require('../modules/dataTextos.json');
let contador = 0;
const Init = () => {
  const dispatch = useDispatch();
  const [percentage, setPercentage] = useState(0)
  useEffect(() => {


    const preloadImages = () => {
      let objectLength = imagesPerLoad.length;
      imagesPerLoad.forEach((item) => {
        if (item.includes('.mp4')) {
          let video = document.createElement("video");
          video.setAttribute("src", require(`../assets/${item}`).default);
          video.load();
          video.oncanplaythrough = () => {
            increasePercentage();
          }
        } else {
          let img = new Image();
          img.onload = () => {
            increasePercentage();
          };
          img.src = require(`../assets/${item}`).default;
        }
      })

      const increasePercentage = (item) => {
        contador++;
        let number = Math.round((contador * 100) / objectLength);
        setPercentage(number)
        finalFunction();
      }
      const finalFunction = () => {
        if (contador === objectLength) {
          setTimeout(() => {
            dispatch({
              type: "SET_DATA",
              data: DataTextos
            })
          }, 1000);
        }
      }
    }
    preloadImages();
  }, [dispatch])

  return (
    <Preloader
      percentage={percentage}
    />
  )

}

export default Init;