import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Creditos from "../components/Inicial/Creditos";
import normalizeString from "../helpers/normalizeString";
import { useTrackedState } from "../store";
import useObtenerItemTraduccion from "./utils/useObtenerItemTraduccion";

const CreditosContainer = () => {
  //Información general
  const state = useTrackedState();
  const { generalData } = state;
  const { continentes, dataTextos, traducciones } = generalData;

  //Información componente
  const posicionTitulo_creditos = useObtenerItemTraduccion("creditos");
  const posicionTitulo_botonContinuar = useObtenerItemTraduccion("continuar");
  const [pending, setPending] = useState(true);
  const [dataCreditos, setDataCreditos] = useState();
  // const []

  //Data url
  const params = useParams();
  const { idioma } = params;


  const palabraTraducida = posicion => {
    return traducciones[posicion][idioma];
  }

  const urlBotonContinuar =()=>{
    let seccion, id, nombreTexto, url;

    continentes.forEach(element => {
      if(element.continente === "prol"){
        seccion = normalizeString(element[idioma]);
      }
    });
    
    let textosIdioma = dataTextos.prol.filter((entry)=>{
      return entry.idioma.indexOf(idioma) !== -1
    })

    id = textosIdioma[0].id;
    nombreTexto = normalizeString(textosIdioma[0].titulo);
    
    url = `/${seccion}/${idioma}/${id}/${nombreTexto}`
    return url;
  }
  
  const items = ['compiladores', 'traductores', 'produccionEditorial', 'desarrolloWeb', 'direccionArte', 'ilustradores', 'disclaimer', 'notaLegal', 'notaProduccion' ]
  const paises = ["camerun","libia","mexico","japon","palestina","libano","siria","brasil","reinoUnido","espana","croacia","eslovenia","peru","lituania","polonia","ukrania","rumania"]
  const textosCreditos = (items) => {
    let textos = {};
    let posiciones = [];
    items.forEach(item=>{
      traducciones.forEach((traduccion, index)=> {
        if(traduccion.item === item){
          posiciones.push(index)
        }
      })
    })

    posiciones.forEach((posicion, index)=>{
      textos[items[index]] = traducciones[posicion][idioma];
    })
    
    return textos;
  }
  useEffect(() => {
    let titulo = palabraTraducida(posicionTitulo_creditos);
    let textoBotonContinuar = palabraTraducida(posicionTitulo_botonContinuar);

    urlBotonContinuar()
    setDataCreditos({
      titulo: titulo,
      textoBotonContinuar: textoBotonContinuar,
      urlContinuar: urlBotonContinuar(),
      textosCreditos : textosCreditos(items),
      paises: textosCreditos(paises)
    })

    setPending(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma])

  return (
    <>
      {!pending && (
        <Creditos
          titulo={dataCreditos.titulo}
          textoBotonContinuar={dataCreditos.textoBotonContinuar}
          idioma={idioma}
          urlContinuar={dataCreditos.urlContinuar}
          textosCreditos={dataCreditos.textosCreditos}
          paises={dataCreditos.paises}
        />
      )}
    </>
  )
}

export default CreditosContainer;