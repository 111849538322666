import React, { useEffect, useRef, useState } from "react";
import { cambiarSaltoLinea } from "../../helpers/cambiarSaltoDeLinea";

const CambiarTamanoTexto = (props) => {

    const { size, texto, height } = props;

    const [fontSize, setFontSize] = useState(size !== undefined ? size : 24);

    const scrollbar = useRef(undefined);

    //Identificador texto como imagen
    const poemaImagen = texto.includes("poemas_imagenes");

    useEffect(() => {
        scrollbar.current.scrollTo(0,0)
    }, [texto])

    const changeFontSize = (value) => {
        var font;
        switch (value) {
            case "decrease":
                font = fontSize > 14 ? fontSize - 2.5 : fontSize
                break;
            case "increase":
                font = fontSize < 34 ? fontSize + 2.5 : fontSize;
                break;
            default:
                font = 24;
        }
        setFontSize(font)
    };


    const createMarkup = (contenido) => {
        return { __html: contenido };
    }

    return (
        <>

            {
                !poemaImagen && (
                    <>
                        <div className="content-buttons--change-font-size ">
                            <button
                                onClick={() => {
                                    changeFontSize("increase");
                                }}
                            >
                                <p>A<sup>+</sup></p>
                            </button>

                            <button
                                onClick={() => {
                                    changeFontSize("decrease");
                                }}
                            >
                                <p>A<sup>-</sup></p>
                            </button>
                        </div>

                        <div
                            className="texto-contenido scrollbar pr-3"
                            dangerouslySetInnerHTML={createMarkup(texto)}
                            ref={scrollbar}
                            style={{ fontSize: `${fontSize}px`, height: height }}
                        />
                    </>
                )
            }
            {
                poemaImagen && (
                    <div
                        className="texto-contenido scrollbar pr-3"
                        ref={scrollbar}
                        style={{height: height }}
                    >
                        {cambiarSaltoLinea("\n", texto)}
                    </div>
                )

            }
        </>
    )

}

export default CambiarTamanoTexto;