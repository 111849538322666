import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {useTrackedState} from "../store";
import Portada from '../components/Portada';
import useObtenerItemTraduccion from './utils/useObtenerItemTraduccion';
import normalizeString from '../helpers/normalizeString';
const PortadaContainer = () => {
  const state = useTrackedState();
  const {generalData} = state;
  const {traducciones} = generalData;
  const posicionTitulo = useObtenerItemTraduccion('titulo');
  const posicionSubtitulo = useObtenerItemTraduccion('subtitulo');
  const posicionCreditos = useObtenerItemTraduccion('creditos');
  const posicionLeer = useObtenerItemTraduccion('leer');
  const [dataPortada, setDataPortada] = useState()
  const [pending, setPending] = useState(true);
  const params = useParams();
  const {idioma} = params;
  const palabraTraducida = (posicion) => {
    return traducciones[posicion][idioma]
  }
  useEffect(()=>{
    let titulo = palabraTraducida(posicionTitulo);
    let subtitulo = palabraTraducida(posicionSubtitulo);
    let creditos = palabraTraducida(posicionCreditos);
    let leer = palabraTraducida(posicionLeer);
    document.title = `${titulo} || ${subtitulo}`;
    setDataPortada({
      titulo: titulo,
      subtitulo: subtitulo,
      link: `${normalizeString(creditos.toLowerCase())}/${idioma}`,
      textoLeer: leer
    })
    setPending(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idioma])
  return(
    <>
      {!pending &&
        <>
          <Portada
            titulo={dataPortada.titulo}
            subtitulo={dataPortada.subtitulo}
            idioma={idioma}
            link={dataPortada.link}
            leer={dataPortada.textoLeer}
          />

        </>
      }
    </>
  )
}

export default PortadaContainer;