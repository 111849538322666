import React from "react";

const SVG_FLECHA = (props) => {

    const { color } = props;

    return (
        <svg className="w-100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 72 112.8" style={{ overflow: "visible", enablebackground: "new 0 0 72 112.8" }} xmlSpace="preserve">
            <defs>
            </defs>
            <g>
                <path
                    style={{
                        fill: color
                    }}
                    d="M72,112.6l-0.4,0.2C53.4,95,25.2,72.4,0,56.6v-0.4C25.2,40.6,53.4,18,71.6,0L72,0.2 c-2.4,10.2-10.2,41.4-20,56.2C62.2,72.8,70.2,101.8,72,112.6z"
                />
            </g>
        </svg>
    )
}

export default SVG_FLECHA;