import {useTrackedState} from "../../store";
const useObtenerIdiomasDisponibles = () => {
  const state = useTrackedState();
  const {generalData} = state;
  const {idiomas} = generalData;
  const idiomasDisponibles = [];
  for(var i in idiomas){
    idiomasDisponibles.push(idiomas[i].objectId)
  }
  return idiomasDisponibles;
}

export default useObtenerIdiomasDisponibles;