import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHeightContainer } from "../../hooks/useHeightContainer";

import "../../scss/Creditos.scss";

const Creditos = (props) => {

    const { paises, textoBotonContinuar, textosCreditos, titulo, urlContinuar } = props;

    const [altoContenedorPadre, contenedorPadre] = useHeightContainer(84);
    const [altoContenedorInfo, contenedorInfo] = useHeightContainer(84);
    const [altoLogos, contenedorLogos] = useHeightContainer(84);

    useEffect(() => {
        document.getElementsByTagName("body")[0].classList.add("fondo-creditos");
        return () => {
            document.getElementsByTagName("body")[0].classList.remove("fondo-creditos");
        }
    }, [])

    return (
        <div className="pl-lg-5 pr-lg-4 creditos height-responsive">
            <div
                className="row mx-0 height-responsive justify-content-between"
                style={{ height: altoContenedorPadre }}
                ref={contenedorPadre}
            >

                <div className="col-lg-6 col-xl-4 px-lg-0">
                    <h2 className="text-uppercase mb-3 mt-4 mt-lg-0">{titulo}</h2>
                    <div
                        className="scrollbar info-creditos"
                        style={{ height: altoContenedorInfo }}
                        ref={contenedorInfo}
                    >
                        <div>
                            <h3>Editorial Colegio Bilingüe José Max León</h3>
                        </div>

                        <div>
                            <h3>{textosCreditos.compiladores}:</h3>
                            <p>
                                Robert Max Steenkist - Colombia <br />
                                Esteban Charpentier - Argentina <br />
                                Mar Russo - Argentina
                            </p>
                        </div>

                        <div>
                            <h3>{textosCreditos.traductores}:</h3>
                            <p>
                                Jaime Velásquez - Colombia <br />
                                Luis Roberto Caicedo Díaz del Castillo - Colombia <br />
                                Ángela M. Gómez - Colombia <br />
                                Sofía Mendoza - Colombia <br />
                                Valentina Mendoza Montes - Colombia <br />
                                Daniel López - Colombia <br />
                                Sara Rueda Villegas - Colombia <br />
                                Susana Fernández Gil - Colombia <br />
                                Daniela Buitrago Ramírez - Colombia <br />
                                Santiago Hincapié Álvarez - Colombia <br />
                                Nelson Kamkuimo - {paises.camerun} <br />
                                James Byrne - {paises.libia} <br />
                                Ashur Etwebi - {paises.libia} <br />
                                Patricia Díaz Bialet - Argentina <br />
                                Sandra Kingery - {paises.mexico} <br />
                                Xánath Caraza - {paises.mexico} <br />
                                Mario Rubén Álvarez - Paraguay <br />
                                Timothy Kercher - Georgia {} <br />
                                Stephen Comee - {paises.japon} <br />
                                Jona y Tobías Burghardt <br />
                                Jawad Wadi  - {paises.palestina} <br />
                                Kaylee Lockett - {paises.libano} <br />
                                Aymen Hacen - {paises.siria} <br />
                                Vincent Zonca - {paises.brasil} <br />
                                Trần Nghi Hoàng - Vietnam <br />
                                Greg Nissan - {paises.reinoUnido} <br />
                                José Louis Reina Palazón - {paises.espana} <br />
                                Theodora Bauer - Austria <br />
                                Hana Samaržija - {paises.croacia} <br />
                                Roumiana Tiholova - {paises.rumania} <br />
                                Julija Potrč & Christopher Meredith - {paises.eslovenia} <br />
                                Nelson Ríos - Colombia <br />
                                Zoila Forss - {paises.peru} <br />
                                Roxana Crisólogo - {paises.peru} <br />
                                Elías Knörr  - {paises.espana} <br />
                                Rimas Uzgiris - {paises.lituania} <br />
                                Graham Crawford - {paises.polonia} <br />
                                Bohdana Neborak - {paises.ukrania}
                            </p>
                        </div>

                        <div>
                            <h3>{textosCreditos.produccionEditorial}</h3>
                            <p>Ana Maria Parra - Colombia</p>
                        </div>

                        <div>
                            <h3>{textosCreditos.desarrolloWeb}:</h3>
                            <p>Mákina Editorial S.A.S.</p>
                        </div>

                        <div>
                            <h3>{textosCreditos.direccionArte}:</h3>
                            <p>
                                Casatinta - Colombia <br />
                                Diseño y diagramación: Fiorella Ferroni <br />
                                Los íconos implementados hacen parte <br />
                                de la colección Noun Project y fueron diseñados <br />
                                por Saifurrijal, Muammar Khalid, Rajesh Rajput, <br />
                                José Manuel de Laá y Yu Luck.
                            </p>
                        </div>

                        <div>
                            <h3>{textosCreditos.ilustradores}:</h3>
                            <p>
                                Cromalario <br />
                                Catalina Vásquez <br />
                                Natalia Rojas <br />
                                Daniel Liévano <br />
                                Fiorella Ferroni <br />
                                Jose Rosero <br />
                                Ricardo Núñez Suárez <br />
                                Samuel Castaño <br />
                                Sandra Restrepo <br />
                                Santiago Guevara
                            </p>
                        </div>
                        
                        <div>
                            <p>{textosCreditos.disclaimer}</p>
                        </div>
                        
                        <div>
                            <p>{textosCreditos.notaLegal}</p>
                        </div>
                        
                        <div>
                            <p>{textosCreditos.notaProduccion}</p>
                        </div>
                    </div>
                </div>


                <div 
                    className="col-lg-6 scrollbar px-lg-0 d-flex flex-wrap justify-content-end align-items-end info-colaboradores" 
                    style={{ height: altoLogos }}
                    ref={contenedorLogos}
                >
                    <div className="text-right col-12 pr-4">
                        <div className="ml-auto d-flex contenedor-logos justify-content-end align-items-end">
                            
                            <div className="d-flex flex-wrap flex-lg-nowrap flex-lg-column px-0 mx-0 mt-4 mt-lg-0">
                                <p className="code-isbn w-100 order-lg-3 mt-3 mb-lg-0">ISBN 978-958-9052-07-5</p>
                                <div className="caja-img col-6 col-lg-7 px-0 order-1 ml-auto d-flex justify-content-center justify-content-lg-end align-items-center ">
                                    <a href='https://josemaxleon.edu.co/' target='_blank' rel='noreferrer'><img className="logo mt-0 col-12 px-0 logo-colegio-bilingue" src={require("../../assets/img/logos/logo3.png").default} alt="Logo Colegio bilingue José Max León" /></a>
                                </div>
                                <div className="caja-img col-6 col-sm-4 col-lg-12 px-0 order-2 d-flex justify-content-center justify-content-lg-end align-items-left">
                                    <a href='http://fundacionpibes.org/' target='_blank' rel='noreferrer'><img className="logo col-12 px-0 logo-pibes" src={require("../../assets/img/logos/logo1.png").default} alt="Logo pibes" /></a>
                                </div>
                                <div className="caja-img col-6 col-sm-4 col-lg-12 px-0 order-2 d-flex justify-content-center justify-content-lg-end align-items-right">
                                    <a href='https://www.nuevayorkpoetrypress.com/' target='_blank' rel='noreferrer'><img className="logo col-12 px-0 logo-new-york" src={require("../../assets/img/logos/logo2.png").default} alt="Logo Nueva york poetry press" /></a>
                                </div>
                                <div className="caja-img col-6 col-lg-7 px-0 order-1 order-lg-2  ml-auto d-flex justify-content-center justify-content-lg-end align-items-center ">
                                    <a href='https://makinaeditorial.com/' target='_blank' rel='noreferrer'><img className="logo col-12 px-0 logo-makina" src={require("../../assets/img/logos/logo4.jpg").default} alt="Logo makina" /></a>
                                </div>
                                <div className="col-6 col-sm-4 col-lg-12 px-0 order-2 d-flex justify-content-center justify-content-lg-end align-items-center ">
                                    <a href='https://casatinta.com/' target='_blank' rel='noreferrer'><img className="logo col-12 px-0 logo-casatinta" src={require("../../assets/img/logos/logo0.png").default} alt="Logo casatinta" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="btn-continuar px-0 text-center d-flex justify-content-end mr-3 mr-lg-2">
                <Link className="text-uppercase" to={urlContinuar}>{textoBotonContinuar}</Link>
            </div>
        </div>
    )
}

export default Creditos;