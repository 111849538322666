import normalizeString from "../../helpers/normalizeString";
import {useTrackedState} from "../../store";
import { useParams } from "react-router-dom";

const useObtenerContinente = () => {
  const state = useTrackedState();
  const {generalData} = state;
  const {continentes} = generalData;
  const params = useParams();
  const {continente, idioma} = params;

  let posicionContinente = null;
  continentes.forEach((item, index)=> {
    if(idioma !== undefined && normalizeString(item[idioma]) === continente){
      posicionContinente = index;
    }
  })
  let abreviaturaContinente = posicionContinente !== null ? continentes[posicionContinente].continente : posicionContinente;
  return [abreviaturaContinente, posicionContinente];
}

export default useObtenerContinente;