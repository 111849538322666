import React, { useEffect, useState } from "react";

//Hooks
import useIsMobile from './../../hooks/useIsMobile';

//Componentes
import SVG_FLECHA from "../../svg/SVG_FLECHA";
import Texto from "./Texto";

//Estilos
import "../../scss/contenido/Biografia.scss"


const Biografia = (props) => {
    const { autor, biografia, textosInterfaz } = props;
    const [mostrarOcultarBiografia, setMostrarOcultarBiografia] = useState(false);

    const isMobile = useIsMobile();

    const mostrarBiografia = () => {
        setMostrarOcultarBiografia(!mostrarOcultarBiografia);
    }

    useEffect(() => {
        setMostrarOcultarBiografia(false)
    }, [biografia])


    return (
        <div className="biografia position-relative row mx-0 my-4 my-lg-0">
            <div className={`encabezado-biografia px-0 col-12 col-lg-auto ${mostrarOcultarBiografia ? "encabezado-biografia-activa" : ""}`} onClick={() => mostrarBiografia()}>
                <p>{textosInterfaz.biografia} {" "} {autor}</p>
                <div className="svg-biografia mx-auto my-1">
                    <SVG_FLECHA />
                </div>
            </div>
            {
                mostrarOcultarBiografia && (
                    <div
                        className="info-biografia"
                    >
                        {
                            mostrarOcultarBiografia && (
                                <Texto
                                    texto={biografia}
                                    size={isMobile ? 21.5 : 19}
                                />
                            )
                        }

                    </div>
                )
            }
        </div>
    )
}

export default Biografia;