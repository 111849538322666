import React from 'react';
import { Link } from "react-router-dom";

import "../../scss/Portada.scss"

const Portada = (props) => {
  /**
    titulo=titulo del libro
    subtitulo=subtitulo del libro
    idioma=debe ir en la clase general para poder modificar los estilos
    link=el link al que debe redirigir el botón de leer
    textoLeer=la palabra que se lee en la parte inferior (leer o read)
  **/

  const { titulo, subtitulo, idioma, link, leer } = props;

  return (
    <>
      <div className={`portada-${idioma}`}>
        <div className="contenedorPortada d-flex justify-content-center align-items-center">
          <div className="contenedor-centrado d-flex  flex-column justify-content-center ">
            <div className="texto-centrado mx-auto">
              <h1 className="d-block m-0">{titulo}</h1>
              <h2 className="d-block m-0">{subtitulo}</h2>
            </div>

            <Link to={link} className="leer">
              {leer}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Portada;