import React from "react";
import { Link } from "react-router-dom";

import "../../scss/contenido/CambiarContenido.scss"

const CambiarContenido = (props) => {

    const { linkAnterior, linkSiguiente, posicionTexto, totalTextos } = props;

    return (
        <div className="cambiar-poema d-flex justify-content-center mb-3">
            <div className="d-flex col-lg-9 mx-auto justify-content-center align-items-center">
                <Link to={linkAnterior} className="col-6 m-0 link-cambiar-poema boton-anterior-poema">
                    Siguiente
              </Link>
                <p>{posicionTexto}/{totalTextos}</p>
                <Link to={linkSiguiente} className="col-6 m-0 link-cambiar-poema boton-siguiente-poema">
                    Anterior
              </Link>
            </div>
        </div>
    )
}

export default CambiarContenido;