import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import Lightbox from "react-image-lightbox";

//Componentes
import SVG_LUPA from "../../svg/SVG_LUPA";
import Texto from "./Texto";

//Hooks
import useIsMobile from './../../hooks/useIsMobile';
import { useHeightContainer } from "../../hooks/useHeightContainer";

//Estilos
import "../../../node_modules/react-image-lightbox/style.css"

/**
 * 
 * @param {
 *  autor:string que corresponde al autor del poema,
 *  continente=string que corresponde al nombre del continente normalizado y en español
 *  image: string y corresponde al nombre de la imagen y ruta de la imagen 
 *  pais: string que corresponde al nombre del país al que pertenece el poema
 *  titulo: string qe corresponde al título del poema
 * }
    
 * @returns 
 */

const Contenido = (props) => {
    //Props
    const { activeTextoAsociado, autor, contenido, continente, idiomaOriginal, ilustrador, imagen, mostrarTextoOriginal, pais, posicionTexto, textosInterfaz, textoOriginal, titulo } = props;

    //Estados
    const [isOpen, setIsOpen] = useState(false);

    //Ruta imagen
    const rutaImagen = require(`../../assets/img${imagen}`).default;
    //Identificador tipo de imagen
    const isVideo = imagen.includes("mp4");

    //Hooks personalizados
    const [altoContenido, contenedorTexto] = useHeightContainer(98);
    const isMobile = useIsMobile();

    const mostrarLightbox = (value) => {
        setIsOpen(value)
    }

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [activeTextoAsociado, contenido, posicionTexto])

  

    useEffect(() => {
        const ocultarToolTip=()=>{
            ReactTooltip.hide();
        }

        var contenedorTexto = document.getElementsByClassName("texto-contenido")[0]

        contenedorTexto.addEventListener("scroll", ocultarToolTip);
        return () => {
            contenedorTexto.removeEventListener("scroll", ocultarToolTip);
        }
    }, [])

    const createMarkup = (contenido) => {
        return { __html: contenido };
    }

    return (
        <div className="row contenedor-poema flex-column flex-lg-row px-3 px-lg-0">
            <div className="pl-0 mt-4 mt-lg-0 mb-3 mb-lg-0 d-flex flex-nowrap flex-column-reverse flex-lg-row align-items-lg-start contenedor-imagen-contenido">
                <div
                    className="mt-2 mt-lg-0 px-0 nombre-ilustrador d-flex d-lg-block align-items-center flex-lg-wrap"
                    onClick={() => !isVideo ? mostrarLightbox(true) : null}
                    style={{ cursor: isVideo ? "initial" : "pointer" }}
                >
                    <div className="px-0 mb-lg-3 d-flex justify-content-center justify-content-lg-end pr-2">
                        <SVG_LUPA color="#2C4C9B" />
                    </div>
                    <div className="pl-1 pr-0 info-ilustrador">
                        <p><em>Ilst.</em> {ilustrador}</p>
                    </div>
                </div>

                <div
                    className="px-0 imagen-poema-container w-100 h-100"
                    onClick={() => !isVideo ? mostrarLightbox(true) : null}
                    style={{ cursor: isVideo ? "initial" : "pointer" }}
                >
                    {
                        !isVideo && <img className="w-100 imagen-poema" src={rutaImagen} alt={`Imagen poema "${titulo}"`} />
                    }
                    {
                        isVideo && (

                            <video className="w-100 imagen-poema video-poema" controls={false} loop playsInline autoPlay muted preload >
                                <source src={rutaImagen} type="video/mp4" />
                            </video>
                        )
                    }
                </div>
            </div>


            <div className={`px-0 pl-lg-2 scrollbar contenido-info position-relative ${continente}`}>
                <div>
                    <div className="contenedor-resaltado-titulo mb-2">
                        <p className="titulo-poema" dangerouslySetInnerHTML={createMarkup(titulo)}/>
                        <p>{autor} {pais !== "" ? ` / ${pais}` : ""}</p>
                    </div>


                    {
                        textoOriginal && (
                            <div className="idioma-original d-flex flex-wrap align-items-center text-uppercase">
                                <p className="mr-3">{textosInterfaz.idiomaOriginal}</p>
                                <div className="mr-3 d-flex align-items-center justify-content-center">
                                    <label className="switch mb-0">
                                        <input onChange={() => mostrarTextoOriginal()} type="checkbox" checked={activeTextoAsociado} />
                                        <span className="slider round" />
                                    </label>
                                </div>
                                <p className="mr-3 mb-0">{idiomaOriginal}</p>
                            </div>
                        )
                    }

                    <div className={`scrollbar mt-2 mt-lg-0 ${continente !== undefined ? "texto-poema" : ""}`} ref={contenedorTexto} >
                        <Texto
                            texto={contenido}
                            height={altoContenido}
                            size={isMobile ? 21.5 : 24}
                        />
                    </div>
                </div>
            </div>

            {
                isOpen && !isVideo && (
                    <Lightbox
                        mainSrc={rutaImagen}
                        onCloseRequest={() => mostrarLightbox(false)}
                        imageCaption={`Ilst. ${ilustrador}`}
                    />
                )
            }
            
            
            <ReactTooltip
                className={'tooltip-citas scrollbar'}
                clickable={true}
                effect={'solid'}
                event={'click'}
                globalEventOff={'click'}
                html={true}
                id="tooltip-citas"
                place="bottom"
                type={'info'}
                scrollHide= {false}
            />
        </div>

    )
}

export default Contenido;